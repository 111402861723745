import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const search = vm.search
      const allRowsHead = [
        [
          { text: vm.$t('org_pro.service_namel') + ' : ' + (search.service_id ? vm.getColumnName(Store.state.licenseRegistration.commonObj.serviceNamesList, search.service_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.year') + ' : ' + (search.year ? vm.$n(search.year, { useGrouping: false }) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.from_date') + ' : ' + dateFormat(search.from_date), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.to_date') + ' : ' + dateFormat(search.to_date), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.type') + ' : ' + (search.application_type ? vm.getApplicationTypeName(search.application_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.payment_type') + ' : ' + (search.paid_type ? vm.getPaymentTypeName(search.paid_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ]
      ]
      pdfContent.push({
        table: {
          widths: ['50%', '50%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      if (vm.listData.length) {
        const allRows = [
          [
            { text: vm.$t('org_pro.service_namel'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.new'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.renew'), style: 'th', alignment: 'center', bold: true },
            { text: vm.date_selected + ' ' + vm.$t('step_assign.application'), style: 'th', alignment: 'center', bold: true },
            { text: vm.date_selected + ' ' + vm.$t('step_assign.approved_application'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.total_approved_application'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.average_day'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.average_percentage'), style: 'th', alignment: 'center', bold: true },
            { text: vm.previus + ' ' + vm.$t('step_assign.un_approved_application'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.total_application'), style: 'th', alignment: 'center', bold: true },
            { text: vm.last_tree_month + ' ' + vm.$t('step_assign.un_approved_application'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        vm.listData.forEach((data, index) => {
          allRows.push([
            { text: vm.getColumnName(Store.state.licenseRegistration.commonObj.serviceNamesList, data.service_id), alignment: 'center', style: 'td' },
            { text: vm.$n(data.new_application, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.reniew_application, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.this_month_application, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.this_month_approve_or_reject, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.total_approve_or_reject, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.dailly, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.percentage, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.previous_day_count_without_approve_or_reject, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.total_application, { useGrouping: false }), alignment: 'right', style: 'td' },
            { text: vm.$n(data.last_3_month_count_without_approve_or_reject, { useGrouping: false }), alignment: 'right', style: 'td' }
          ])
        })
        allRows.push(
          [
            { text: '', style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(vm.totalSum('new_application'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('reniew_application'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('this_month_application'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('this_month_approve_or_reject'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('total_approve_or_reject'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n((vm.totalSum('dailly')) / vm.listData.length, { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n((vm.totalSum('percentage')) / vm.listData.length, { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('previous_day_count_without_approve_or_reject'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('total_application'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true },
            { text: vm.$n(vm.totalSum('last_3_month_count_without_approve_or_reject'), { useGrouping: false }), style: 'th', alignment: 'right', bold: true }
          ]
        )
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '8%', '8%', '11%', '12%', '9%', '8%', '8%', '8%', '8%', '10%'],
            // widths: '*',
            body: allRows
          }
        })
      } else {
        pdfContent.push({ text: vm.$t('globalTrans.noDataFound'), style: 'fertilizer', alignment: 'center' })
      }
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 9 : 7,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        },
        header1: {
          fontSize: 15,
          margin: [5, 5, 5, 5]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
