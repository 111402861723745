<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('li_step.acceptance_and_disposal') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Service Name" vid="service_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro.service_namel') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.service_id"
                                    :options="serviceNamesList"
                                    id="service_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="year"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    v-model="search.year"
                                    :options="yearList"
                                    id="year"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="From Date" vid="from_date">
                          <b-form-group
                            :label="$t('globalTrans.from_date')"
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.from_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.from_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="To Date" vid="to_date">
                          <b-form-group
                            :label="$t('globalTrans.to_date')"
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.to_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.to_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Application Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.application_type"
                              :options="applicationTypeList"
                              id="application_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Payment Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="paid_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.payment_type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.paid_type"
                              :options="paymentTypeList"
                              id="paid_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-if="showData">
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.acceptance_and_disposal') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" :org-id="search.org_id">
                                        {{ $t('li_step.acceptance_and_disposal') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('org_pro.service_namel') }}: <strong>{{ search.service_id ? getServiceName(search.service_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.year') }}: <strong>{{ search.year ? $n(search.year, { useGrouping: false }) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.from_date') }} : <strong>{{ search.from_date | dateFormat }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.to_date') }} : <strong>{{ search.to_date | dateFormat }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.type') }}: <strong>{{ search.application_type ? getApplicationTypeName(search.application_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.payment_type') }}: <strong>{{ search.paid_type ? getPaymentTypeName(search.paid_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="listData.length">
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:15%">{{$t('org_pro.service_namel')}}</b-th>
                                            <b-th style="width:10%">{{$t('step_assign.new')}}</b-th>
                                            <b-th style="width:10%">{{$t('step_assign.renew')}}</b-th>
                                            <b-th style="width:10%">
                                              {{ date_selected }} {{$t('step_assign.application')}}
                                            </b-th>
                                            <b-th style="width:12%">{{ date_selected }} {{$t('step_assign.approved_application')}}</b-th>
                                            <b-th style="width:11%">{{$t('step_assign.total_approved_application')}}</b-th>
                                            <b-th style="width:12%">{{$t('step_assign.average_day')}}</b-th>
                                            <b-th style="width:12%">{{$t('step_assign.average_percentage')}}</b-th>
                                            <b-th style="width:12%">{{ previus }} {{$t('step_assign.un_approved_application')}}</b-th>
                                            <b-th style="width:12%">{{$t('step_assign.total_application')}}</b-th>
                                            <b-th style="width:12%">{{ last_tree_month }} {{$t('step_assign.un_approved_application')}}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <b-tr v-for="(data, index) in listData" :key="index">
                                            <b-td class="text-center" >{{ getColumnName($store.state.licenseRegistration.commonObj.serviceNamesList, data.service_id)}}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.new_application, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.reniew_application, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.this_month_application, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.this_month_approve_or_reject, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.total_approve_or_reject, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.dailly, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.percentage, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.previous_day_count_without_approve_or_reject, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.total_application, { useGrouping: false }) }}</b-td>
                                            <b-td style="text-align:right !important">{{ $n(data.last_3_month_count_without_approve_or_reject, { useGrouping: false }) }}</b-td>
                                          </b-tr>
                                        </b-tbody>
                                        <b-tfoot>
                                          <b-tr key="foot" v-if="listData.length">
                                            <b-th></b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('new_application'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('reniew_application'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('this_month_application'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('this_month_approve_or_reject'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('total_approve_or_reject'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n((totalSum('dailly'))/parseInt(listData.length), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n((totalSum('percentage'))/parseInt(listData.length), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('previous_day_count_without_approve_or_reject'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('total_application'), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSum('last_3_month_count_without_approve_or_reject'), { useGrouping: false }) }}</b-th>
                                          </b-tr>
                                        </b-tfoot>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.table thead th, td {
  vertical-align: middle !important;
  text-align: center;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { acceptanceAndDisposalReport } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import ExportPdf from './export_pdf'
import { helpers } from '@/mixins/helper-functions.js'
import flatpickr from 'flatpickr'
import { dateFormat } from '@/Utils/fliter'

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      search: {
        org_id: 2,
        service_id: 0,
        year: 0,
        from_date: '',
        to_date: '',
        application_type: 0,
        paid_type: 0
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      listData: [],
      grandTotalInfo: {},
      excelColumnBn: {},
      excelColumn: {},
      showData: false,
      threeMonthsAgo: '',
      date_selected: this.$t('step_assign.current_month'),
      previus: this.$t('step_assign.previus'),
      last_tree_month: this.$t('step_assign.last_tree_month')
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
        return this.$store.state.commonObj.loading
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    serviceNamesList () {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === 2))
    },
    yearList () {
      return helpers.getYearListCurrent({ yearFrom: 2021 })
    },
    monthList () {
      return this.$store.state.commonObj.monthList
    },
    applicationTypeList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ', text_en: 'Reneiw', text_bn: 'রিনিউ' }
      ]
      return list
    },
    paymentTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          text_en: 'Offline',
          text_bn: 'অফলাইন'
        }
      ]
    },
    authOrgId () {
      const authUser = this.$store.state.Auth.authUser
      const activeRoleId = this.$store.state.Auth.activeRoleId
      return activeRoleId !== 1 ? authUser.org_id : 0
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      if (newVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      } else {
        this.serviceNamesList = []
      }
    }
  },
  created () {
    if (this.authOrgId) {
      this.search.org_id = this.authOrgId
    }
  },
  mounted () {
    core.index()
    flatpickr('.datepicker', {})
  },
  methods: {
    totalSum (field) {
      let total = 0
      this.listData.forEach((info, index) => {
        if (info[field] > 0) {
          total += parseFloat(info[field])
        }
      })
      return total
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    dailyFinisthed (data1, data2) {
      if (parseFloat(data1) && parseFloat(data2)) {
        return (parseFloat(data1) / parseFloat(data2)).toFixed(2)
      }
      return ''
    },
    averageInfo (data1, data2) {
      let changePercentage = 0
      if (parseFloat(data1) && parseFloat(data2)) {
        changePercentage = (data2 / data1) * 100
      }
      return changePercentage.toFixed(2)
    },
    pdfExport () {
      const reportTitle = this.$t('li_step.acceptance_and_disposal')
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
    },
    getRelationalData (data) {
      return data.map(item => {
        const objectData = {
          dailly: this.dailyFinisthed(item.total_days_difference, item.total_app_or_rej_those_time),
          percentage: this.averageInfo(item.total_application, item.total_approve_or_reject)
        }
        return Object.assign({}, item, objectData)
      })
    },
    async loadData () {
      this.showData = true
      this.listData = []
      this.grandTotalInfo = {}
      this.$store.commit('mutateCommonProperties', {
          loading: true
      })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, acceptanceAndDisposalReport, this.search)
      if (result.success) {
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
        this.listData = this.getRelationalData(result.data)
        if (this.search.from_date && this.search.to_date) {
          this.date_selected = dateFormat(this.search.from_date) + ' ' + this.$t('globalTrans.from') + ' ' + dateFormat(this.search.to_date) + ' ' + this.$t('globalTrans.dates')
          this.previus = dateFormat(result.previousDay) + ' ' + this.$t('globalTrans.to_projonto')
          this.last_tree_month = dateFormat(result.threeMonthsAgo) + ' ' + this.$t('globalTrans.to_projonto')
        }
      } else {
        this.listData = []
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
      }
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === orgId))
      return tmpService.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getOrganizationName (id) {
      const obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getServiceName (id) {
      const obj = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getApplicationTypeName (id) {
      const obj = this.applicationTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getPaymentTypeName (id) {
      const obj = this.paymentTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    }
  }
}
</script>
<style >
.table-bordered th{
border: 1px solid #000 !important;
}
</style>
